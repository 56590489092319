html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    outline: none;
    box-sizing: border-box;
}

input, select, textarea, button {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-size: 62.5%;
    line-height: 1;
    font-family: Arial, Tahoma, sans-serif;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
}

strong {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

img {
    border: 0;
    max-width: 100%;
}

.clearfix:before, .clearfix:after {
    content: '';
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

body {
    font-size: 14px;
    line-height: 1.5;
    color: #646464;
    font-family: '微软雅黑', '宋体', Arial, Verdana, Geneva, Helvetica, sans-serif;
}

input, textarea {
    margin: 0;
    padding: 0;
}

input:focus, textarea:focus, button /*,*:focus*/
{
    outline: none;
}

input, select, textarea, button {
    vertical-align: middle;
}

table, tbody, tr, th, td {
    vertical-align: middle;
}

a {
    color: #646464;
    text-decoration: none;
    outline: none;
}

a:active, a:hover {
    text-decoration: none;
    /*color: #1e78eb;*/
}

a:active {
    star: expression(this.onFocus=this.blur());
}

a:hover {
    text-decoration: none;
}

p {
    text-indent: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

i {
    font-style: normal;
}

input[type="button"], input[type="submit"], input[type="reset"], input[type="text"], textarea {
    -webkit-appearance: none;
}

a, a:hover, a:active, a:visited, a:link, a:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    text-decoration: none;
}
html,
body {
  height: 100%;
  background-color: #fff;
}
body > iframe {
  display: none;
}
#root,
.root {
  min-height: 100vh;
}
.root {
  display: flex;
}
.dialog {
  position: fixed;
  z-index: 10005;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.dialog-mask {
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}
.dialog-content {
  position: relative;
  z-index: 10003;
}
.dialog-toast {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.dialog-toast .toast-icon {
  font-size: 32px;
}
.dialog-toast .toast-text {
  font-size: 16px;
}
.dialog-toast .toast-text:nth-child(2) {
  margin-top: 5px;
}
.dialog-modal {
  border-radius: 8px;
  min-width: 70vw;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.25s;
}
.dialog-modal .modal-body {
  padding: 20px;
  margin-top: -20px;
  max-height: 50vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.dialog-modal .modal-title {
  font-size: 18px;
  color: #000;
  margin-top: 20px;
  text-align: center;
}
.dialog-modal .modal-content {
  margin-top: 20px;
  color: #333;
  font-size: 15px;
}
.dialog-modal .modal-btns {
  border-top: 1px solid #eee;
  display: flex;
}
.dialog-modal .modal-btn {
  flex: 1 1;
  padding: 12.5px;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #000;
  border-left: 1px solid #eee;
}
.dialog-modal .modal-btn:first-child {
  border-left: none;
}
.dialog-modal .modal-btn.success {
  color: #108ee9;
}
.dialog-modal .modal-btns.column {
  flex-direction: column;
}
.dialog-modal .modal-btns.column .modal-btn {
  flex: 1 1;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #333;
  border-left: none;
  border-top: 1px solid #eee;
}
.dialog-modal .modal-btns.column .modal-btn:first-child {
  border-top: none;
}
.dialog.enter {
  opacity: 0;
}
.dialog.enter .dialog-modal {
  -webkit-transform: scale(1.25, 1.25);
          transform: scale(1.25, 1.25);
}
.dialog.enter-active,
.dialog.enter-done,
.dialog.exit {
  opacity: 1;
}
.dialog.enter-active .dialog-modal,
.dialog.enter-done .dialog-modal,
.dialog.exit .dialog-modal {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.dialog.exit-active {
  opacity: 0;
}
.dialog.exit-active .dialog-modal {
  -webkit-transform: scale(1.25, 1.25);
          transform: scale(1.25, 1.25);
}
.ks-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5%;
}
.ks-empty .icon {
  max-width: 100%;
}
.ks-empty .icon img {
  display: block;
}
.ks-empty .text {
  margin-top: 5px;
}
.tips-index {
  flex: 1 1;
  width: 0;
}
.tips-index .container {
  border-radius: 8px;
  overflow: hidden;
}
.tips-index .head {
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff5c5c;
  height: 40px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.tips-index .head .title {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.tips-index .head .close {
  color: #fff;
  font-size: 18px;
  position: absolute;
  right: 2.5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.tips-index .body {
  padding: 40px 0 30px 0;
}
.tips-index .body .body-container {
  padding: 10px;
  min-height: calc(100vh - 40px - 30px);
  background-color: #fff;
}
.tips-index .body .title {
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}
.tips-index .body .content {
  font-size: 13px;
  margin-top: 15px;
}
.tips-index .foot {
  position: fixed;
  z-index: 22;
  bottom: 0;
  left: 0;
  right: 0;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.tips-index .foot .items {
  display: flex;
  align-items: center;
}
.tips-index .foot .items .item {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #c3c3c3;
}
.tips-index .foot .items .item.active {
  background-color: #fc3f40;
}
.tips-index .foot .ctrl {
  display: flex;
  align-items: center;
}
.tips-index .foot .ctrl .input {
  border: 1px solid #d6d6d6;
  border-radius: 50%;
  width: 17.5px;
  height: 17.5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.tips-index .foot .ctrl .input .icon {
  font-size: 10px;
  transition: all 0.25s;
  opacity: 0;
}
.tips-index .foot .ctrl .input.active {
  background-color: #fe7072;
  border: none;
}
.tips-index .foot .ctrl .input.active .icon {
  opacity: 1;
  color: #fff;
}
.tips-index .foot .ctrl .text {
  color: #373737;
  font-weight: bold;
}
.center-container {
  flex: 1 1;
  width: 0;
  display: none;
}
.center-container.active {
  display: flex;
}
.center-container .center-head {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  background-color: #f9f9f9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.center-container .center-head .title {
  font-weight: 600;
  color: #242424;
  font-size: 15px;
}
.center-container .center-body {
  flex: 1 1;
  width: 0;
  padding-top: 45px;
  display: flex;
}
.center-container .center-body.nohead {
  padding-top: 0;
}
.side-container {
  flex: 1 1;
  width: 0;
  display: flex;
  position: relative;
}
.side-container .side-menu {
  position: fixed;
  background-color: #f8f7f6;
  width: 55px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.side-container .side-menu .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.side-container .side-menu .item .icon {
  font-size: 24px;
  position: relative;
  line-height: 1.1;
}
.side-container .side-menu .item .text {
  font-size: 13px;
  line-height: 1.25;
  margin-top: 0.25em;
}
.side-container .side-menu .item.badge .icon:before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 5px;
  background-color: red;
  border-radius: 50%;
}
.side-container .side-menu .item.active {
  color: #ff6464;
}
.side-container .side-main {
  flex: 1 1;
  width: 0;
  display: flex;
  padding-left: 55px;
}
.side-container .side-item {
  flex: 1 1;
  width: 0;
  display: none;
}
.side-container .side-item.active {
  display: flex;
}
.side-container .side-title {
  height: 45px;
  position: fixed;
  left: 55px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.side-container .side-title .title {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  font-size: 15px;
  color: #242424;
  text-align: center;
}
.side-container .side-body-container {
  flex: 1 1;
  width: 0;
  display: none;
}
.side-container .side-body-container.active {
  display: flex;
}
.side-container .side-screen {
  flex: 1 1;
  width: 0;
  display: flex;
}
.side-container .side-screen .side-body {
  flex: 1 1;
  width: 0;
  display: flex;
}
.side-container .side-screen.has-title .side-body {
  padding-top: 45px;
}
.side-container .side-views {
  flex: 1 1;
  width: 0;
  display: none;
}
.side-container .side-views.active {
  display: flex;
}
.side-container.nouser .side-menu {
  display: none;
}
.side-container.nouser .side-main {
  padding-left: 0;
}
.side-container.nouser .side-title {
  left: 0;
}
.side-navigation {
  flex: 1 1;
  width: 0;
  display: none;
  padding-top: 45px;
}
.side-navigation.active {
  display: flex;
}
.side-navigation .nav-title {
  height: 45px;
  position: fixed;
  z-index: 22;
  top: 0;
  left: 55px;
  right: 0;
  color: #242424;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  font-size: 15px;
  background-color: #fff;
}
.side-navigation .nav-title .back {
  padding: 5px;
  font-size: 16px;
  width: 45px;
}
.side-navigation .nav-title .title {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.side-navigation .nav-title .operate {
  width: 45px;
}
.side-navigation .nav-body {
  flex: 1 1;
  width: 0;
}
.test-from input {
  border: 1px solid #666;
  width: 100%;
  padding: 5px;
  line-height: 2;
}
.account-index {
  flex: 1 1;
  width: 0;
  display: flex;
  padding: 10px;
}
.account-head {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  background-color: #fff;
}
.account-head .item {
  font-size: 15px;
  color: #000000;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.25s;
}
.account-head .item:after {
  content: "";
  width: 50%;
  height: 0;
  background-image: linear-gradient(#fe7072, #fc3d3e);
  border-radius: 2px;
  transition: all 0.25s;
}
.account-head .item.active:after {
  height: 4px;
}
.account-body {
  flex: 1 1;
  width: 0;
  display: flex;
  padding-top: 45px;
}
.account-body .active-container {
  flex: 1 1;
  width: 0;
  display: none;
}
.account-body .active-container.active {
  display: flex;
}
.account-register {
  flex: 1 1;
  width: 0;
}
.account-register .tips {
  color: #ccc;
}
.account-register .input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-size: 15px;
}
.account-register .input .label:after {
  content: "：";
}
.account-register .input input {
  flex: 1 1;
  padding: 5px;
  border: none;
  background-color: transparent;
}
.account-register .btn {
  margin-top: 15px;
  background-color: #fc3d3e;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.agreement {
  margin-top: 10px;
  text-align: center;
}
.agreement a {
  color: #fc3d3e;
}
.account-phone-login {
  flex: 1 1;
  width: 0;
  display: flex;
  flex-direction: column;
}
/* .account-phone-login .body {
  flex: 1;
  height: 0;
} */
.account-phone-login .tips {
  color: #ccc;
}
.account-phone-login .input {
  background-color: #eee;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.account-phone-login .input input {
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  background: none;
  flex: 1 1;
  width: 0;
}
.account-phone-login .input .opt {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #fc3d3e;
  color: #fc3d3e;
  line-height: 1.25;
}
.account-phone-login .codebtn {
  color: #fc3d3e;
  border-left: 1px solid #fc3d3e;
  font-size: 14px;
  line-height: 1.25;
  padding-left: 5px;
  margin: 5px;
}
.account-phone-login .codebtn.disable {
  color: #cccccc;
  border-left-color: #cccccc;
}
.account-phone-login .btn {
  margin-top: 15px;
  background-color: #fc3d3e;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.account-password-login {
  flex: 1 1;
  width: 0;
}
.account-password-login .tips {
  color: #ccc;
}
.account-password-login .input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eee;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-size: 15px;
}
.account-password-login .input .label:after {
  content: "：";
}
.account-password-login .input input {
  flex: 1 1;
  width: 0;
  padding: 5px;
  border: none;
  background-color: transparent;
}
.account-password-login .input .opt {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #fc3d3e;
  color: #fc3d3e;
  line-height: 1.25;
}
.account-password-login .btn {
  margin-top: 15px;
  background-color: #fc3d3e;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.account-agreement {
  flex: 1 1;
  width: 0;
}
.account-agreement iframe {
  width: 100%;
  height: 100%;
}
.account-forget {
  flex: 1 1;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 20px;
}
.account-forget .item {
  display: flex;
  justify-content: space-between;
  background-color: #eee;
  padding: 10px;
  border-radius: 4px;
  margin-top: 15%;
}
.account-forget .item:first-child {
  margin-top: 0;
}
.account-forget .item:after {
  content: ">";
  display: block;
}
.account-forget-find {
  flex: 1 1;
  width: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.account-forget-find .body {
  flex: 1 1;
  height: 0;
}
.account-forget-find .tips {
  color: #ccc;
}
.account-forget-find .input {
  background-color: #eee;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
}
.account-forget-find .input input {
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  background: none;
}
.account-forget-find .btn {
  margin-top: 15px;
  background-color: #fc3d3e;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.account-forget-set {
  flex: 1 1;
  width: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.account-forget-set .body {
  flex: 1 1;
  height: 0;
}
.account-forget-set .tips {
  color: #ccc;
}
.account-forget-set .input {
  background-color: #eee;
  border-radius: 4px;
  margin-top: 10px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.account-forget-set .input input {
  padding: 5px 10px;
  font-size: 15px;
  border: none;
  background: none;
  flex: 1 1;
  width: 0;
}
.account-forget-set .input .opt {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #fc3d3e;
  color: #fc3d3e;
  line-height: 1.25;
}
.account-forget-set .codebtn {
  color: #fc3d3e;
  border-left: 1px solid #fc3d3e;
  font-size: 14px;
  line-height: 1.25;
  padding-left: 5px;
  margin: 5px;
}
.account-forget-set .codebtn.disable {
  color: #cccccc;
  border-left-color: #cccccc;
}
.account-forget-set .btn {
  margin-top: 15px;
  background-color: #fc3d3e;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.center-container.alt .center-head .user {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}
.center-container.alt .center-head .user .avatar {
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  background-color: #d8d8d8;
  margin-right: 7.5px;
}
.center-container.alt .center-head .user .name {
  font-size: 14px;
  color: #242424;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.center-container.alt .center-head .operate {
  color: #242424;
  font-weight: bold;
}
.center-container.alt .alt-index {
  flex: 1 1;
  width: 0;
  padding: 20px;
}
.alt-index .alt-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alt-index .alt-head .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alt-index .alt-head .title .text {
  color: #242424;
  font-size: 14px;
  margin-right: 7.5px;
}
.alt-index .alt-head .title .icon {
  color: #999999;
}
.alt-index .alt-head .operate {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alt-index .alt-head .operate .add {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.alt-index .alt-head .operate .add .icon {
  color: #979797;
  margin-right: 7.5px;
}
.alt-index .alt-head .operate .add .text {
  color: #ff6464;
  font-size: 15px;
  font-weight: bold;
}
.alt-index .alt-head .operate .test {
  margin-left: 10px;
  color: #ff6464;
  font-size: 17px;
  font-weight: bold;
}
.alt-index .alt-body {
  margin-top: 12.5px;
}
.alt-index .alt-body .alt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 8px;
  margin-top: 12.5px;
}
.alt-index .alt-body .alt:first-child {
  margin-top: 0;
}
.alt-index .alt-body .alt.active {
  border: 1px solid #ff6464;
}
.alt-index .alt-body .alt .select input {
  margin-right: 7.5px;
  font-size: 16px;
}
.alt-index .alt-body .alt.active .select {
  color: #ff6464;
}
.alt-index .alt-body .alt .name {
  flex: 1 1;
  padding: 0 7.5px;
  font-size: 15px;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.alt-index .alt-body .alt .operate {
  display: flex;
  align-items: center;
}
.alt-index .alt-body .alt .operate .text {
  color: #ff6464;
}
.alt-index .alt-body .alt .operate .icon {
  color: #020203;
  margin-left: 5px;
}
.alt-index .alt-body .alt.disable {
  border-color: #eee;
}
.alt-index .alt-body .alt.disable .select {
  color: #ccc;
}
.alt-index .alt-body .alt.disable .name {
  color: #ccc;
}
.alt-index .alt-body .alt.disable .operate .text {
  color: #ccc;
}
.alt-index .alt-body .alt.disable .operate .icon {
  color: #ccc;
}
.alt-alt {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
}
.alt-alt .body {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.alt-alt .tips {
  font-weight: 400;
  color: #7f7f7f;
  font-size: 13px;
}
.alt-alt input {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 15px;
  margin-top: 15px;
  border: none;
  line-height: 2;
}
.alt-alt .operate {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #ff9191 0%, #ff5858 100%);
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  color: #fff;
  font-size: 15px;
}
.alt-alt .operate .icon {
  margin-right: 5px;
  transition: all 0.25s;
  opacity: 0;
}
.alt-alt .operate.saving .icon {
  opacity: 1;
}
.pay-pay {
  background-color: #fff;
  flex: 1 1;
  width: 0;
  font-size: 0.24rem;
  display: flex;
}
.pay-pay .pay-head {
  position: fixed;
  z-index: 917;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.pay-pay .pay-head .title {
  font-weight: bold;
  color: #000000;
  font-size: 15px;
}
.pay-pay .pay-head .test {
  position: absolute;
  left: 5px;
  padding: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #363f4d;
}
.pay-pay .pay-head .back {
  position: absolute;
  left: 5px;
  padding: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  color: #363f4d;
}
.pay-pay .pay-head .close {
  position: absolute;
  right: 5px;
  padding: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 18px;
  color: #363f4d;
}
.pay-pay .pay-body {
  flex: 1 1;
  width: 0;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
}
.pay-pay .pay-body .pay-main {
  flex: 1 1;
  height: 0;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pay-pay .pay-body .pay-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.pay-pay .pay-body .pay-info .item {
  margin-top: 5px;
}
.pay-pay .pay-body .pay-info .item:first-child {
  margin-top: 0;
}
.pay-pay .pay-body .pay-info span {
  color: #fe7072;
}
.pay-pay .pay-body .pay-info .voucher-autoed {
  display: inline;
  color: #ff5c5c;
  background-color: #eee;
  padding: 2.5px 5px;
  line-height: 1.25;
  margin-top: 5px;
}
.pay-pay .pay-body .pay-data .pay-item {
  margin-top: 0.1rem;
  display: flex;
  justify-content: space-between;
}
.pay-pay .pay-body .pay-data .pay-item.disable {
  opacity: 0.75;
}
.pay-pay .pay-body .pay-data .pay-item > .name {
  font-weight: bold;
  color: #4a4a4a;
  font-size: 0.28rem;
  display: flex;
  align-items: center;
}
.pay-pay .pay-body .pay-data .pay-item > .value {
  display: flex;
  align-items: center;
}
.pay-pay .pay-body .pay-data .pay-item > .value .more {
  font-size: 15px;
  color: #333333;
  margin-left: 5px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.order {
  color: #4a4a4a;
}
.pay-pay .pay-body .pay-data .pay-item > .value.voucher .text {
  color: #ff5c5c;
}
.pay-pay .pay-body .pay-data .pay-item > .value.subsidy .switch {
  margin-right: 5px;
  background-color: #ccc;
  border-radius: 20px;
  width: 35px;
  height: 17.5px;
  position: relative;
  overflow: hidden;
  transition: all 0.25s;
}
.pay-pay .pay-body .pay-data .pay-item > .value.subsidy .switch:before {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  background-color: #fff;
  width: 50%;
  border-radius: 50%;
  transition: all 0.25s;
}
.pay-pay .pay-body .pay-data .pay-item > .value.subsidy .switch.active {
  background-color: green;
}
.pay-pay .pay-body .pay-data .pay-item > .value.subsidy .switch.active:before {
  left: auto;
  right: 1px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.subsidy .value {
  color: red;
  font-size: 0.26rem;
  font-weight: normal;
  margin-right: 5px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.subsidy .tips {
  color: #ccc;
  font-size: 0.28rem;
  font-weight: normal;
}
.pay-pay .pay-body .pay-data .pay-item > .value.method .all {
  display: flex;
  align-items: center;
  margin-right: 25px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.method .all .item {
  margin-right: 5px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.method .all img {
  height: 22.5px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.method .current {
  display: flex;
  align-items: center;
}
.pay-pay .pay-body .pay-data .pay-item > .value.method .current img {
  display: block;
  margin-right: 5px;
  height: 22.5px;
}
.pay-pay .pay-body .pay-data .pay-item > .value.method .current .text {
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pay-pay .pay-body .pay-data .pay-item > .value.pay {
  color: #ff5c5c;
}
.pay-pay .pay-body .operate {
  margin: 0.15rem;
  background: linear-gradient(270deg, #ff9191 0%, #ff5858 100%);
  border-radius: 4px;
  font-size: 0.34rem;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.15rem;
}
.pay-pay .pay-body .operate .icon {
  margin-right: 10px;
  display: none;
}
.pay-pay .pay-body .operate.ing .icon {
  display: block;
}
.pay-pay .pay-iframe {
  position: fixed;
  z-index: 55;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
}
.pay-pay .pay-iframe:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height: 10%;
  background-color: #fff;
}
.pay-pay .pay-iframe iframe {
  width: 100%;
  height: 100%;
}
.pay-pay .pay-dialog {
  position: fixed;
  z-index: 22;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  opacity: 0;
}
.pay-pay .pay-dialog > .bg {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.pay-pay .pay-dialog > .container {
  position: relative;
  z-index: 2;
  background: #ffffff;
  border-radius: 8px;
  padding: 12.5px;
  width: 85%;
  min-height: 50%;
  max-height: 95%;
  transition: all 0.25s;
  opacity: 0;
  -webkit-transform: scale(1.25, 1.25);
          transform: scale(1.25, 1.25);
}
.pay-pay .pay-dialog.appear-active,
.pay-pay .pay-dialog.appear-done,
.pay-pay .pay-dialog.enter-active,
.pay-pay .pay-dialog.enter-done {
  opacity: 1;
}
.pay-pay .pay-dialog.appear-active > .container,
.pay-pay .pay-dialog.appear-done > .container,
.pay-pay .pay-dialog.enter-active > .container,
.pay-pay .pay-dialog.enter-done > .container {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.pay-pay .pay-voucher-select {
  z-index: 918;
}
.pay-pay .pay-voucher-select > .container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 90%;
  min-height: 80%;
  max-height: 90%;
}
.pay-pay .pay-voucher-select .head {
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  margin-top: -5px;
  position: relative;
}
.pay-pay .pay-voucher-select .head .title {
  font-weight: bold;
  color: #000000;
}
.pay-pay .pay-voucher-select .head .close {
  position: absolute;
  right: 0;
  padding: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #363f4d;
}
.pay-pay .pay-voucher-select .body {
  flex: 1 1;
  height: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.pay-pay .pay-voucher-select .body .tips {
  margin-top: 7.5px;
  display: flex;
}
.pay-pay .pay-voucher-select .body .tips .text {
  padding: 0 5px;
  background: #ff5c5c;
  border-radius: 2px;
  color: #fff;
}
.pay-pay .pay-voucher-select .body .operate {
  margin-top: 7.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pay-pay .pay-voucher-select .body .operate .name {
  font-weight: 500;
  color: #373737;
}
.pay-pay .pay-voucher-select .body .operate .value {
  display: flex;
  align-items: center;
}
.pay-pay .pay-voucher-select .body .operate .value .item {
  margin-left: 15px;
  width: 17.5px;
  height: 17.5px;
  border: 2px solid #ccc;
  border-radius: 50%;
  transition: all 0.25s;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay-pay .pay-voucher-select .body .operate .value .item .icon {
  opacity: 0;
}
.pay-pay .pay-voucher-select .body .operate .value .item.active {
  background-color: #ff5c5c;
  border-color: #ff5c5c;
}
.pay-pay .pay-voucher-select .body .operate .value .item.active .icon {
  opacity: 1;
}
.pay-pay .pay-voucher-select .body .items {
  margin-top: 7.5px;
  flex: 1 1;
  height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.pay-pay .pay-voucher-select .body .items .item {
  margin-top: 12.5px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  position: relative;
}
.pay-pay .pay-voucher-select .body .items .item:first-child {
  margin-top: 0;
}
.pay-pay .pay-voucher-select .body .items .item .amount {
  background: #ff6464;
  color: #fff;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.pay-pay .pay-voucher-select .body .items .item .amount .value {
  display: flex;
  align-items: flex-end;
  line-height: 1.25;
}
.pay-pay .pay-voucher-select .body .items .item .amount .value .unit {
  font-size: 13px;
  padding-bottom: 2.5px;
}
.pay-pay .pay-voucher-select .body .items .item .amount .value .number {
  font-size: 20px;
  font-weight: bold;
}
.pay-pay .pay-voucher-select .body .items .item .amount .desc {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.pay-pay .pay-voucher-select .body .items .item .info {
  flex: 1 1;
  width: 0;
  border: 2px solid #ff6464;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 7.5px;
}
.pay-pay .pay-voucher-select .body .items .item .info .name .type {
  display: inline;
  background: #ff6464;
  color: #fff;
  font-size: 12px;
  margin-right: 5px;
  padding: 2px 2.5px 3px 2.5px;
  border-radius: 2px;
}
.pay-pay .pay-voucher-select .body .items .item .info .name .named {
  display: inline;
  font-weight: 500;
  color: #242424;
  line-height: 13px;
}
.pay-pay .pay-voucher-select .body .items .item .info .desc {
  color: #a6a6a6;
  margin-top: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}
.pay-pay .pay-voucher-select .body .items .item .active {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
}
.pay-pay .pay-voucher-select .body .items .item .active .icon {
  color: #fff;
  font-size: 12px;
  position: relative;
  z-index: 2;
  line-height: 1;
  padding-right: 2.5px;
  top: -2.5px;
}
.pay-pay .pay-voucher-select .body .items .item .active:before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 30px solid #fe7072;
  border-left: 30px solid transparent;
}
.pay-pay .pay-voucher-select .body .items .item.active .active {
  opacity: 1;
}
.pay-pay .pay-method-select .container {
  display: flex;
  flex-direction: column;
}
.pay-pay .pay-method-select .title {
  font-weight: bold;
  color: #000000;
  font-size: 15px;
  text-align: center;
}
.pay-pay .pay-method-select .items {
  padding: 12.5px 0;
  flex: 1 1;
  height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.pay-pay .pay-method-select .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12.5px;
}
.pay-pay .pay-method-select .item:first-child {
  margin-top: 0;
}
.pay-pay .pay-method-select .item > .icon {
  margin-right: 7.5px;
}
.pay-pay .pay-method-select .item > .icon img {
  display: block;
  height: 22.5px;
}
.pay-pay .pay-method-select .item .name {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.pay-pay .pay-method-select .item .name .tips {
  font-size: 12px;
  color: #ccc;
  margin-left: 5px;
}
.pay-pay .pay-method-select .item .state {
  width: 22.5px;
  height: 22.5px;
  border: 2px solid #ccc;
  border-radius: 50%;
  transition: all 0.25s;
  color: #fff;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pay-pay .pay-method-select .item .state .icon {
  opacity: 0;
  transition: all 0.25s;
}
.pay-pay .pay-method-select .item.active .state {
  background-color: #ff5c5c;
  border-color: #ff5c5c;
}
.pay-pay .pay-method-select .item.active .state .icon {
  opacity: 1;
}
.pay-pay .pay-method-select .item.disable {
  opacity: 0.75;
}
.pay-pay .pay-success > .container {
  display: flex;
  flex-direction: column;
  padding: 0;
}
.pay-pay .pay-success .body {
  flex: 1 1;
  height: 0;
  overflow: hidden;
  padding: 12.5px;
}
.pay-pay .pay-success .icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff5c5c;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}
.pay-pay .pay-success .title {
  font-weight: bold;
  color: #373737;
  font-size: 16px;
  margin-top: 12.5px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.pay-pay .pay-success .ad {
  margin-top: 12.5px;
  width: 100%;
  max-height: 25vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pay-pay .pay-success .ad a {
  width: 100%;
  min-height: 50%;
  background-color: #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pay-pay .pay-success .ad img {
  display: block;
  max-width: 100%;
  background-color: #eee;
}
.pay-pay .pay-success .close {
  margin-top: 12.5px;
  font-size: 16px;
  color: #373737;
  font-weight: bold;
  border-top: 1px solid #eee;
  padding: 12.5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-index {
  flex: 1 1;
  width: 0;
}
.user-index-msg {
  display: flex;
  align-items: flex-start;
  padding: 10px 15px;
}

.user-index-msg .icon {
  font-size: 22px;
}
.user-index-msg .badge {
  padding: 0 8px;
  height: 15px;
  line-height: 15px;
  font-size: 12px;
  text-align: center;
  color: #fff;
  background-color: red;
  border-radius: 30px;
  -webkit-animation: shake 3s linear infinite alternate running;
          animation: shake 3s linear infinite alternate running; /*动画名称*/
}
@-webkit-keyframes shake {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
}
@keyframes shake {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateY(-3px);
            transform: translateY(-3px);
  }
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform: translateY(3px);
            transform: translateY(3px);
  }
}
.user-index .head {
  display: flex;
  align-items: center;
  padding: 10px;
}
.user-index .head .avatar {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 50%;
  overflow: hidden;
}
.user-index .head .avatar img {
  display: block;
  width: 100%;
  height: 100%;
}
.user-index .head .data {
  flex: 1 1;
  padding: 0 10px;
}
.user-index .head .data .username {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.user-index .head .data .username .value {
  font-weight: bold;
  color: #000;
}
.user-index .head .data .username .vip {
  color: red;
  margin-left: 5px;
}
.user-index .head .data .username .copy {
  margin-left: 5px;
  font-size: 16px;
}
.user-index .head .data .ptb {
  color: #ff6464;
  font-size: 15px;
}
.user-index .head .voucher {
  color: #ff6464;
  border: 1px solid #ff6464;
  padding: 5px;
  border-radius: 8px;
}
.user-index .item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #eee;
}
.user-index .item .text {
  color: #242424;
}
.user-index .item .operate {
  color: #333333;
}
.user-index .alt {
  border-top: 5px solid #eee;
  border-bottom: 5px solid #eee;
}
.user-index .alt .item {
  border-bottom: none;
}
.user-alt {
  position: relative;
  padding: 10px;
}
.user-alt .alt-title {
  position: fixed;
  top: 45px;
  left: 55px;
  right: 0;
  height: 35px;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 13px;
  color: #a6a6a6;
  background-color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-alt .alt-items {
  padding-top: 25px;
  padding-bottom: 50px;
}
.user-alt .alt-items .item {
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 4px;
  padding: 10px;
}
.user-alt .alt-items .item:first-child {
  margin-top: 0;
}
.user-alt .alt-items .item .select input {
  margin-right: 7.5px;
  font-size: 16px;
}
.user-alt .alt-items .item .name {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #242424;
  padding: 0 7.5px;
}
.user-alt .alt-items .item.current .select,
.user-alt .alt-items .item.default .select {
  color: #ff6464;
}
.user-alt .alt-items .item.current .name,
.user-alt .alt-items .item.default .name {
  color: #fe7072;
}
.user-alt .alt-items .item.current .name:before {
  content: "当前小号：";
  color: #242424;
}
.user-alt .alt-items .item .opt {
  display: flex;
  align-items: center;
  color: #fe7072;
}
.user-alt .alt-items .item .opt .switch {
  border: 1px solid #fe7072;
  border-radius: 4px;
  padding: 2.5px 5px;
  font-size: 13px;
  margin-right: 10px;
}
.user-alt .alt-items .item .opt .modify {
  font-size: 14px;
}
.user-alt .alt-items .item.current .switch {
  display: none;
}
.user-alt .alt-items .item.disable {
  background-color: #eee;
}
.user-alt .alt-items .item.disable .name {
  color: #ccc;
}
.user-alt .alt-items .item.disable .switch {
  color: #ccc;
  border-color: #eee;
}
.user-alt .alt-items .item.disable .modify {
  color: #ccc;
}
.user-alt .alt-operate {
  position: fixed;
  left: 55px;
  right: 0;
  bottom: 0;
  margin: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  padding: 0 7.5px;
  border-radius: 4px;
  color: #fff;
  font-size: 15px;
}
.user-alt .alt-operate:before {
  content: "";
  display: block;
  height: 7.5px;
  background-color: #ffffff;
  left: 0;
  right: 0;
  position: absolute;
  bottom: 100%;
}
.user-alt .alt-from .input {
  margin-top: 5px;
}
.user-alt .alt-from .input input {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
}
.user-orders .items {
  padding: 10px;
}
.user-orders .item {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.user-orders .item .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-orders .item .row .name {
  color: #a6a6a6;
}
.user-orders .item .row .value {
  color: #a6a6a6;
}
.user-orders .item .row:first-child .name {
  color: #242424;
  font-size: 15px;
}
.user-orders .item .row:first-child .value {
  color: #ff6464;
  font-size: 16px;
}
.user-gifts .body {
  padding: 10px;
}
.user-gifts .types {
  display: flex;
}
.user-gifts .types .type {
  background-color: #ccc;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  margin-left: 10px;
}
@media (max-width: 375px) {
  .user-gifts .types .type {
    font-size: 13px;
  }
}
.user-gifts .types .type:first-child {
  margin-left: 0;
}
.user-gifts .types .type.active {
  background: #ff6464;
}
.user-gifts .items {
  padding: 10px;
}
.user-gifts .items .item {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.user-gifts .items .item .body {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.user-gifts .items .item .body .icon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
}
.user-gifts .items .item .body .icon img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.user-gifts .items .item .body .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5px 0;
}
.user-gifts .items .item .body .info .name {
  font-size: 15px;
  color: #242424;
  font-weight: bold;
}
.user-gifts .items .item .body .info .time {
  color: #a6a6a6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-gifts .items .item .foot {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-gifts .items .item .foot .code {
  display: flex;
  align-items: center;
}
.user-gifts .items .item .foot .code .name {
  color: #fe7072;
}
.user-gifts .items .item .foot .code .value {
  color: #242424;
  font-size: 15px;
}
.user-gifts .items .item .foot .operate {
  color: #fe7072;
}
.user-gifts.empty {
  display: flex;
  flex-direction: column;
}
.user-gifts [class*="empty"] {
  flex: 1 1;
  height: 0;
}
.user-password {
  padding: 12.5px;
  height: 100%;
}
.user-password .title {
  color: #a6a6a6;
  font-size: 13px;
}
.user-password .title span {
  color: #ff6464;
}
.user-password .input {
  margin-top: 10px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.user-password .input input {
  flex: 1 1;
  width: 0;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
  background-color: #efefef;
}
.user-password .input .codebtn {
  margin-left: 10px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  padding: 7.5px 10px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.user-password .input .codebtn.disable {
  background-image: none;
  background-color: #ccc;
}
.user-password .tips1 {
  color: #a6a6a6;
}
.user-password .tips2 {
  margin-top: 10px;
  color: #242424;
}
.user-password .operate {
  margin-top: 20px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  text-align: center;
  padding: 7.5px;
  font-size: 15px;
  border-radius: 4px;
}
.user-phone {
  padding: 12.5px;
  height: 100%;
}
.user-phone .input {
  margin-top: 10px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.user-phone .input input {
  flex: 1 1;
  width: 0;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
  background-color: #efefef;
}
.user-phone .input .codebtn {
  margin-left: 10px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  padding: 7.5px 10px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.user-phone .input .codebtn.disable {
  background-image: none;
  background-color: #ccc;
}
.user-phone .operate {
  margin-top: 20px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  text-align: center;
  padding: 7.5px;
  font-size: 15px;
  border-radius: 4px;
}
.user-phone .phone-index {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.user-phone .phone-index img {
  max-width: 75%;
}
.user-phone .phone-index p {
  margin-top: 25px;
  font-size: 15px;
  color: #242424;
  font-weight: bold;
}
.user-phone .phone-index span {
  margin: 10px;
  color: #fe7072;
  font-size: 15px;
  cursor: pointer;
}
.user-phone .phone-verify .tips1 {
  color: #a6a6a6;
}
.user-phone .phone-verify .tips1 span {
  color: #fe7072;
  font-weight: bold;
}
.user-phone .phone-verify .tips2 {
  margin-top: 10px;
  color: #242424;
}
.user-phone .phone-phone .title {
  color: #a6a6a6;
  font-size: 13px;
}
.user-phone .phone-phone .title span {
  color: #ff6464;
}
.user-anti-addiction {
  padding: 12.5px;
}
.user-anti-addiction .data-success {
  color: #242424;
}
.user-anti-addiction .data-error {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #242424;
}
.user-anti-addiction .data-error h1 {
  text-align: center;
}
.user-anti-addiction .data-error span {
  color: #fe6465;
}
.user-anti-addiction .data-error .body {
  flex: 1 1;
  height: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.user-anti-addiction .data-error .operate {
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  text-align: center;
  padding: 7.5px;
  font-size: 15px;
  border-radius: 4px;
}
.user-anti-addiction .data-from .title {
  color: #a6a6a6;
  font-size: 13px;
}
.user-anti-addiction .data-from .input {
  margin-top: 10px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.user-anti-addiction .data-from .input input {
  flex: 1 1;
  width: 0;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
  background-color: #efefef;
}
.user-anti-addiction .data-from .input .codebtn {
  margin-left: 10px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  padding: 7.5px 10px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.user-anti-addiction .data-from .input .codebtn.disable {
  background-image: none;
  background-color: #ccc;
}
.user-anti-addiction .data-from .tips {
  margin-top: 12.5px;
}
.user-anti-addiction .data-from .operate {
  margin-top: 20px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  text-align: center;
  padding: 7.5px;
  font-size: 15px;
  border-radius: 4px;
}
.user-msgs {
  word-wrap: break-word;
  word-break: normal;
  word-break: break-all;
}
.user-msgs .items {
  padding: 10px;
}
.user-msgs .item {
  display: block;
  border-top: 1px solid #eee;
  margin-top: 7.5px;
  padding-top: 7.5px;
}
.user-msgs .item:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.user-msgs .item .head {
  display: flex;
  align-items: center;
}
.user-msgs .item .head .title {
  color: #242424;
  flex: 1 1;
  width: 0;
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.user-msgs .item .head .title .titled {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 5px;
  color: #242424;
}
.user-msgs .item .head .title .badge:before {
  content: "News";
  display: block;
  color: #fe7072;
  white-space: nowrap;
}
.user-msgs .item .head .time {
  color: #a2a2a2;
}
.user-msgs .item .body {
  color: #7a7a7a;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.user-msgs .item.read .head .title {
  color: #ccc;
}
.user-msgs .item.read .head .title .titled {
  color: #ccc;
}
.user-msgs .item.read .head .title .badge:before {
  display: none;
}
.user-msgs .item.read .head .time {
  color: #ccc;
}
.user-msgs .item.read .body {
  color: #ccc;
}
.user-msg {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.user-msg .body {
  flex: 1 1;
  padding: 10px;
}
.user-msg .title {
  text-align: right;
}
.user-msg .title span {
  color: #ff6464;
  border: 1px solid #ff6464;
  padding: 5px;
  border-radius: 8px;
}
.user-msg .content {
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}
.user-msg .time {
  padding: 10px;
  text-align: right;
}
.welfare-index {
  flex: 1 1;
  width: 0;
  display: flex;
}
.welfare-index .welfare-head {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  left: 55px;
  right: 0;
  height: 45px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.welfare-index .welfare-head .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.25s;
}
.welfare-index .welfare-head .item .text {
  font-size: 15px;
  color: #a6a6a6;
  font-weight: bold;
  position: relative;
}
.welfare-index .welfare-head .item.badge .text:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: red;
}
.welfare-index .welfare-head .item:after {
  content: "";
  width: 50%;
  height: 0;
  background-image: linear-gradient(#fe7072, #fc3d3e);
  border-radius: 2px;
  transition: all 0.25s;
}
.welfare-index .welfare-head .item.vip {
  color: #ffaa47;
}
.welfare-index .welfare-head .item.active:not(.vip) {
  color: #242424;
}
.welfare-index .welfare-head .item.active:after {
  height: 4px;
}
.welfare-index .welfare-body {
  padding-top: 45px;
  flex: 1 1;
  width: 0;
  display: flex;
}
.welfare-index .welfare-item {
  flex: 1 1;
  width: 0;
  display: none;
}
.welfare-index .welfare-item.active {
  display: flex;
}
.voucher-vouchers {
  flex: 1 1;
  width: 0;
}
.voucher-vouchers .body {
  padding: 12.5px;
}
.voucher-vouchers .monthcard {
  background: #ff6464;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 75px;
}
.voucher-vouchers .monthcard div {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.voucher-vouchers .monthcard h1 {
  color: #ffffff;
  font-size: 16px;
}
.voucher-vouchers .monthcard h1 span {
  font-size: 18px;
}
.voucher-vouchers .monthcard p {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  margin-top: 5px;
  opacity: 0.5;
}
.voucher-vouchers .monthcard > span {
  background: #ffbe04;
  border-radius: 15px 15px 0 15px;
  padding: 5px 10px;
  font-weight: 500;
  color: #242424;
}
.voucher-vouchers .item {
  margin-top: 12.5px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.voucher-vouchers .item .amount {
  background: #ff6464;
  color: #fff;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.voucher-vouchers .item .amount .value {
  display: flex;
  align-items: flex-end;
  line-height: 1.25;
}
.voucher-vouchers .item .amount .value .unit {
  font-size: 14px;
  padding-bottom: 2.5px;
}
.voucher-vouchers .item .amount .value .number {
  font-size: 24px;
  font-weight: bold;
}
.voucher-vouchers .item .amount .desc {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-vouchers .item .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  border: 2px solid #ff6464;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.voucher-vouchers .item .info .data {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.voucher-vouchers .item .info .data .name .type {
  display: inline;
  background: #ff6464;
  color: #fff;
  font-size: 13px;
  margin-right: 5px;
  padding: 2px 2.5px 3px 2.5px;
  border-radius: 2px;
}
.voucher-vouchers .item .info .data .name .named {
  display: inline;
  font-weight: 500;
  color: #242424;
  line-height: 14px;
}
.voucher-vouchers .item .info .data .desc {
  color: #a6a6a6;
  margin-top: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-vouchers .item .info .operate {
  margin-left: 5px;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 5px 10px;
  text-align: center;
  transition: all 0.25s;
}
.voucher-vouchers .item .info .operate:before {
  content: "领取";
}
.voucher-vouchers .item .info .operate.nothing,
.voucher-vouchers .item .info .operate.receiving,
.voucher-vouchers .item .info .operate.received {
  background: #ccc;
}
.voucher-vouchers .item .info .operate.nothing:before {
  content: "已领完";
}
.voucher-vouchers .item .info .operate.receiving:before {
  content: "领取中";
}
.voucher-vouchers .item .info .operate.received:before {
  content: "已领取";
}
.voucher-vouchers.empty {
  display: flex;
  flex-direction: column;
}
.voucher-vouchers.empty [class*="empty"] {
  flex: 1 1;
  height: 0;
}
@media (max-width: 320px) {
  .voucher-vouchers .item .info {
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
  }
  .voucher-vouchers .item .info .data {
    flex: none;
    width: auto;
  }
  .voucher-vouchers .item .info .operate {
    margin-top: 5px;
  }
}
.voucher-voucher {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.voucher-voucher .head {
  padding: 10px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.voucher-voucher .head .amount {
  background: #ff6464;
  color: #fff;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.voucher-voucher .head .amount .value {
  display: flex;
  align-items: flex-end;
  line-height: 1.25;
}
.voucher-voucher .head .amount .value .unit {
  font-size: 14px;
  padding-bottom: 2.5px;
}
.voucher-voucher .head .amount .value .number {
  font-size: 24px;
  font-weight: bold;
}
.voucher-voucher .head .amount .desc {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-voucher .head .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  border: 2px solid #ff6464;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.voucher-voucher .head .info .data {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.voucher-voucher .head .info .data .name .type {
  display: inline;
  background: #ff6464;
  color: #fff;
  font-size: 13px;
  margin-right: 5px;
  padding: 2px 2.5px 3px 2.5px;
}
.voucher-voucher .head .info .data .name .named {
  display: inline;
  font-weight: 500;
  color: #242424;
  line-height: 14px;
}
.voucher-voucher .head .info .data .desc {
  color: #a6a6a6;
  margin-top: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.voucher-voucher .body {
  flex: 1 1;
  height: 0;
  overflow-y: auto;
  padding: 10px;
}
.voucher-voucher .body table {
  width: 100%;
}
.voucher-voucher .body table tr td {
  padding: 5px;
  word-wrap: break-word;
}
.voucher-voucher .body table tr td:first-child {
  width: 75px;
  color: #a6a6a6;
}
.voucher-voucher .body table tr td:last-child {
  color: #242424;
}
.voucher-voucher .foot {
  border-top: 1px solid #eee;
  padding: 10px;
}
.voucher-voucher .foot .operate {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px;
  transition: all 0.25s;
}
.voucher-voucher .foot .operate:before {
  content: "领取";
}
.voucher-voucher .foot .operate.nothing,
.voucher-voucher .foot .operate.receiving,
.voucher-voucher .foot .operate.received {
  background: #ccc;
}
.voucher-voucher .foot .operate.nothing:before {
  content: "已领完";
}
.voucher-voucher .foot .operate.receiving:before {
  content: "领取中";
}
.voucher-voucher .foot .operate.received:before {
  content: "已领取";
}
.user-voucher {
  height: 100%;
}
.user-voucher .head {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 45px;
  left: 55px;
  right: 0;
  height: 45px;
  border-bottom: 1px solid #eee;
  background-color: #fff;
}
.user-voucher .head .item {
  font-size: 15px;
  color: #a6a6a6;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.25s;
}
.user-voucher .head .item:after {
  content: "";
  width: 50%;
  height: 0;
  background-image: linear-gradient(#fe7072, #fc3d3e);
  border-radius: 2px;
  transition: all 0.25s;
}
.user-voucher .head .item.vip {
  background-image: linear-gradient(#ffd3a0, #ffaa47, #905106);
  -webkit-background-clip: text;
  color: transparent;
}
.user-voucher .head .item.active:not(.vip) {
  color: #242424;
}
.user-voucher .head .item.active:after {
  height: 4px;
}
.user-voucher .body {
  padding-top: 45px;
  height: 100%;
}
.user-voucher .body .items {
  padding: 12.5px;
}
.user-voucher .body .items .item {
  margin-top: 12.5px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.user-voucher .body .items .item:first-child {
  margin-top: auto;
}
.user-voucher .body .items .item .amount {
  background: #ff6464;
  color: #fff;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.user-voucher .body .items .item .amount .value {
  display: flex;
  align-items: flex-end;
  line-height: 1.25;
}
.user-voucher .body .items .item .amount .value .unit {
  font-size: 14px;
  padding-bottom: 2.5px;
}
.user-voucher .body .items .item .amount .value .number {
  font-size: 24px;
  font-weight: bold;
}
.user-voucher .body .items .item .amount .desc {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-voucher .body .items .item .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  border: 2px solid #ff6464;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.user-voucher .body .items .item .info .data {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.user-voucher .body .items .item .info .data .name .type {
  display: inline;
  background: #ff6464;
  color: #fff;
  font-size: 13px;
  margin-right: 5px;
  padding: 2px 2.5px 3px 2.5px;
  border-radius: 2px;
}
.user-voucher .body .items .item .info .data .name .named {
  display: inline;
  font-weight: 500;
  color: #242424;
  line-height: 14px;
}
.user-voucher .body .items .item .info .data .desc {
  color: #a6a6a6;
  margin-top: 2.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-voucher .body .items .item .info .state {
  margin-left: 5px;
  width: 50px;
}
.user-voucher .body .items .item .info .state > * {
  display: none;
  width: 100%;
}
.user-voucher .body .items .item.state-2 .amount,
.user-voucher .body .items .item.state-3 .amount {
  background-color: #ccc;
}
.user-voucher .body .items .item.state-2 .info,
.user-voucher .body .items .item.state-3 .info {
  border-color: #ccc;
}
.user-voucher .body .items .item.state-2 .info .data .name .type,
.user-voucher .body .items .item.state-3 .info .data .name .type {
  background: #ccc;
}
.user-voucher .body .items .item.state-2 .state-2 {
  display: block;
}
.user-voucher .body .items .item.state-3 .state-3 {
  display: block;
}
.rebate-rebates {
  flex: 1 1;
  width: 0;
}
.rebate-rebates .items {
  padding: 10px;
}
.rebate-rebates .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding-top: 7.5px;
  margin-top: 7.5px;
}
.rebate-rebates .item:first-child {
  margin-top: 0;
  border-top: none;
  padding-top: 0;
}
.rebate-rebates .item .data {
  flex: 1 1;
  width: 0;
  overflow: hidden;
}
.rebate-rebates .item .data .name {
  font-weight: 600;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-rebates .item .data .desc {
  color: #a6a6a6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-rebates .item .operate {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rebate-rebates .item .operate:before {
  content: "申请";
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 5px 10px;
  transition: all 0.25s;
}
.rebate-rebates .item .operate.receiving:before,
.rebate-rebates .item .operate.submited:before {
  background: #ccc;
}
.rebate-rebates .item .operate.receiving:before {
  content: "申请中";
}
.rebate-rebates .item .operate.submited:before {
  content: "已申请";
}
.rebate-rebates .item .operate.auto:before {
  content: "无需申请";
  color: #fff;
  background: #a6a6a6;
  margin-top: 2.5px;
}
.rebate-rebates .item .operate.auto:after {
  content: "自动返利";
  color: #fe7072;
  margin-top: 2.5px;
}
.rebate-rebate {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.rebate-rebate .body {
  flex: 1 1;
  height: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.rebate-rebate .head {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.rebate-rebate .head .icon {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
}
.rebate-rebate .head .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.rebate-rebate .head .info {
  width: 0;
  overflow: hidden;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rebate-rebate .head .info .name {
  font-weight: 600;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-rebate .head .info .desc {
  font-weight: 500;
  color: #a6a6a6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.rebate-rebate .data {
  background: #f1f1f1;
  padding: 10px;
  flex: 1 1;
  height: 0;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 10px;
  word-wrap: break-word;
  word-break: break-all;
}
.rebate-rebate .require {
  margin-top: 10px;
}
.rebate-rebate .require span {
  color: #fe7072;
}
.rebate-rebate .foot {
  border-top: 1px solid #eee;
  padding: 10px;
}
.rebate-rebate .foot .select {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
.rebate-rebate .foot .select select {
  width: 0;
  flex: 1 1;
  height: 35px;
  border: 1px solid #ccc;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px;
}
.rebate-rebate .foot .operate {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px;
  transition: all 0.25s;
}
.rebate-rebate .foot .operate:before {
  content: "申请";
}
.rebate-rebate .foot .operate.nothing {
  background: #a6a6a6;
}
.rebate-rebate .foot .operate.nothing:before {
  content: "未达到条件";
  color: #fff;
}
.rebate-rebate .foot .operate.complete {
  background: #a6a6a6;
}
.rebate-rebate .foot .operate.complete:before {
  content: "已申请完成";
  color: #fff;
}
.rebate-rebate .foot .operate.auto {
  background: #a6a6a6;
}
.rebate-rebate .foot .operate.auto:before {
  content: "无需申请";
  color: #fff;
}
.user-rebates {
  height: 100%;
}
.user-rebates .items {
  padding: 10px;
}
.user-rebates .item {
  display: block;
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}
.user-rebates .item:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}
.user-rebates .item .body {
  display: flex;
  align-items: center;
}
.user-rebates .item .body .icon {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
}
.user-rebates .item .body .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.user-rebates .item .body .data {
  flex: 1 1;
  width: 0;
  overflow: hidden;
}
.user-rebates .item .body .data .name {
  font-weight: 600;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-rebates .item .body .data .desc {
  color: #a6a6a6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-rebates .item .foot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-rebates .item .foot .state {
  color: #a6a6a6;
}
.user-rebates .item .foot .state-0:after {
  content: "申请中";
}
.user-rebates .item .foot .state-1:after {
  content: "审核成功";
}
.user-rebates .item .foot .state-2:after {
  content: "处理完成";
}
.user-rebates .item .foot .state--1:after {
  content: "申请驳回";
}
.user-rebates .item .foot .opt {
  background-color: #fc3d3e;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 8px;
  color: #fff;
  font-size: 12px;
}
.user-rebate {
  padding: 10px;
}
.user-rebate .data .field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.5px;
}
.user-rebate .data .field:first-child {
  margin-top: auto;
}
.user-rebate .data .field .name {
  padding-right: 10px;
  color: #a6a6a6;
}
.user-rebate .data .field .value {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-rebate .info {
  border-top: 1px solid #eee;
  margin-top: 5px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-rebate .info span {
  color: #ff6464;
}
.user-rebate .tips {
  border-top: 1px solid #ccc;
  margin-top: 5px;
  padding: 5px 0;
}
.user-rebate .tips .title {
  font-weight: bold;
  text-align: center;
}
.user-rebate .tips .desc {
  border-top: 1px solid #eee;
  padding-top: 5px;
  margin-top: 5px;
  font-size: 11px;
}
.user-rebate .tips .desc p {
  display: flex;
  align-items: center;
}
.user-rebate .tips .desc p .icon {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fe7072;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}
.user-rebate .opt {
  border-top: 1px solid #ccc;
  margin-top: 7.5px;
  padding: 7.5px 0;
}
.user-rebate .opt .btn {
  display: block;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  color: #fff;
  text-align: center;
  padding: 7.5px 5px;
}
.user-rebate .opt .desc {
  color: #a6a6a6;
  font-size: 11px;
  text-align: center;
  margin-top: 7.5px;
}
.gift-gifts {
  flex: 1 1;
  width: 0;
}
.gift-gifts .body {
  padding: 10px;
}
.gift-gifts .types {
  display: flex;
}
.gift-gifts .types .type {
  background-color: #ccc;
  border-radius: 5px;
  color: #fff;
  padding: 5px 10px;
  margin-left: 10px;
}
@media (max-width: 375px) {
  .gift-gifts .types .type {
    font-size: 13px;
  }
}
.gift-gifts .types .type:first-child {
  margin-left: 0;
}
.gift-gifts .types .type.active {
  background: #ff6464;
}
.gift-gifts .items .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 7.5px;
  margin-top: 7.5px;
}
.gift-gifts .items .item .data {
  flex: 1 1;
  width: 0;
  overflow: hidden;
}
.gift-gifts .items .item .data .name {
  font-weight: 600;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-gifts .items .item .data .desc {
  color: #a6a6a6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-gifts .items .item .operate {
  margin-left: 10px;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 5px 10px;
  transition: all 0.25s;
}
.gift-gifts .items .item .operate:before {
  content: "领取";
}
.gift-gifts .items .item .operate.nothing,
.gift-gifts .items .item .operate.receiving,
.gift-gifts .items .item .operate.received {
  background: #ccc;
}
.gift-gifts .items .item .operate.nothing:before {
  content: "已领完";
}
.gift-gifts .items .item .operate.receiving:before {
  content: "领取中";
}
.gift-gifts .items .item .operate.received:before {
  content: "已领取";
}
.gift-gifts .items .item .operate.copy:before {
  content: "复制";
}
.gift-gifts.empty {
  display: flex;
  flex-direction: column;
}
.gift-gifts [class*="empty"] {
  flex: 1 1;
  height: 0;
}
.gift-vipgifts {
  flex: 1 1;
  width: 0;
}
.gift-vipgifts .items {
  padding: 12.5px;
}
.gift-vipgifts .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 7.5px;
  margin-top: 7.5px;
}
.gift-vipgifts .item .data {
  flex: 1 1;
  width: 0;
  overflow: hidden;
}
.gift-vipgifts .item .data .name {
  display: flex;
}
.gift-vipgifts .item .data .name .named {
  font-weight: 600;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-vipgifts .item .data .name .vip {
  margin-left: 5px;
  display: flex;
  align-items: center;
}
.gift-vipgifts .item .data .name .vip img {
  height: 15px;
}
.gift-vipgifts .item .data .desc {
  color: #a6a6a6;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.gift-vipgifts .item .operate {
  margin-left: 10px;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 5px 10px;
  transition: all 0.25s;
}
.gift-vipgifts .item .operate:before {
  content: "领取";
}
.gift-vipgifts .item .operate.nothing,
.gift-vipgifts .item .operate.receiving,
.gift-vipgifts .item .operate.received {
  background: #ccc;
}
.gift-vipgifts .item .operate.nothing:before {
  content: "已领完";
}
.gift-vipgifts .item .operate.receiving:before {
  content: "领取中";
}
.gift-vipgifts .item .operate.received:before {
  content: "已领取";
}
.gift-gift {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.gift-gift .body {
  flex: 1 1;
  height: 0;
  overflow-y: auto;
  padding: 10px;
  width: 100%;
}
.gift-gift .body .head {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.gift-gift .body .head .icon {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-right: 10px;
}
.gift-gift .body .head .icon img {
  display: block;
  width: 100%;
  height: 100%;
}
.gift-gift .body .head .info {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.gift-gift .body .head .info .name {
  font-weight: 600;
  color: #242424;
}
.gift-gift .body .head .info .desc {
  font-weight: 500;
  color: #a6a6a6;
}
.gift-gift .body .operate {
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  padding-bottom: 7.5px;
}
.gift-gift .body .operate .info {
  color: #fe7072;
}
.gift-gift .body .operate .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 5px 10px;
  transition: all 0.25s;
  font-size: 13px;
}
.gift-gift .body .data {
  padding: 10px 0;
}
.gift-gift .body .data h1 {
  color: #242424;
  font-weight: 600;
}
.gift-gift .body .data p {
  font-size: 13px;
  color: #242424;
  word-wrap: break-word;
  word-break: break-all;
}
.gift-gift .foot {
  border-top: 1px solid #eee;
  padding: 10px;
}
.gift-gift .foot .operate {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(270deg, #fe7072 0%, #fc3d3e 100%);
  border-radius: 4px;
  font-weight: 500;
  color: #ffffff;
  padding: 10px;
  transition: all 0.25s;
}
.gift-gift .foot .operate:before {
  content: "领取";
}
.gift-gift .foot .operate.nothing,
.gift-gift .foot .operate.receiving,
.gift-gift .foot .operate.received {
  background: #ccc;
}
.gift-gift .foot .operate.nothing:before {
  content: "已领完";
}
.gift-gift .foot .operate.receiving:before {
  content: "领取中";
}
.gift-gift .foot .operate.received:before {
  content: "已领取";
}
.gift-receive {
  position: fixed;
  z-index: 10001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.gift-receive .body {
  min-width: 60vw;
  max-width: 70vw;
  max-height: 60vh;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.gift-receive .content {
  flex: 1 1;
  height: 0;
  padding: 0.25rem;
}
.gift-receive .content .card {
  border: 1px solid #ff5c5c;
  background-color: rgba(255, 92, 92, 0.25);
  padding: 0.1rem;
  font-size: 0.3rem;
  border-radius: 4px;
}
.gift-receive .content .title {
  margin-top: 0.1rem;
  font-size: 0.28rem;
}
.gift-receive .content .info {
  margin-top: 0.1rem;
  font-size: 0.28rem;
}
.gift-receive .foot {
  border-top: 1px solid #eee;
}
.gift-receive .foot .btn div {
  padding: 0.2rem;
  text-align: center;
  color: #ff5c5c;
}
.trade-trades {
  flex: 1 1;
  width: 0;
}
.trade-trades .trade-head {
  position: fixed;
  top: 45px;
  left: 55px;
  right: 0;
  padding: 12.5px;
  border-bottom: 5px solid #eee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 105px;
  background-color: #fff;
}
.trade-trades .trade-head .container {
  border: 2px solid #ff6464;
  padding: 12.5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade-trades .trade-head .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
}
.trade-trades .trade-head .title {
  font-size: 15px;
  color: #242424;
}
.trade-trades .trade-head .desc {
  font-size: 14px;
  color: #a6a6a6;
}
.trade-trades .trade-head .operate {
  border: 1px solid #ff6464;
  color: #ff6464;
  padding: 7.5px;
  font-size: 15px;
  border-radius: 4px;
}
.trade-trades .trade-body {
  padding-top: 105px;
  height: 100%;
}
.trade-trades .trade-body .items {
  padding-bottom: 10px;
}
.trade-trades .trade-body .item {
  padding: 12.5px 12.5px 0 12.5px;
}
.trade-trades .trade-body .item .head {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding-bottom: 7.5px;
  margin-bottom: 10px;
}
.trade-trades .trade-body .item .head .time {
  color: #a6a6a6;
}
.trade-trades .trade-body .item .head .price {
  color: #ff6464;
  font-size: 15px;
  font-weight: bold;
}
.trade-trades .trade-body .item .body {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.trade-trades .trade-body .item .body .icon {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.trade-trades .trade-body .item .body .icon img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.trade-trades .trade-body .item .body .info {
  flex: 1 1;
  width: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.trade-trades .trade-body .item .body .info .name {
  font-size: 15px;
  color: #242424;
  font-weight: bold;
}
.trade-trades .trade-body .item .body .info .server {
  color: #ff6464;
}
.trade-trades .trade-body .item .body .info .game {
  font-size: 13px;
  color: #a6a6a6;
}
.server-servers {
  flex: 1 1;
  width: 0;
}
.server-servers .title {
  font-size: 15px;
  color: #ff6464;
  text-align: center;
  padding: 10px;
}
.server-servers .list {
  padding: 0 10px 10px 10px;
}
.server-servers .list table {
  width: 100%;
}
.server-servers .list td {
  border: 1px solid #eee;
  padding: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.server-servers.empty {
  display: flex;
  flex-direction: column;
}
.server-servers.empty [class*="empty"] {
  flex: 1 1;
  height: 0;
}
.service-index {
  flex: 1 1;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.service-index .service-container {
  flex: 1 1;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.service-index .service-container .service-img img {
  width: 80%;
  height: auto;
  margin: 0 auto;
  display: block;
}
.service-index .service-container .service-img p {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}
.service-index .service-container .btn {
  width: 130px;
  padding: 10px 0;
  margin-top: 10px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}
.service-index .service-container .btn:first-child {
  margin-top: 0;
}
.service-index .service-version {
  padding-bottom: 50px;
}
.realname-index {
  padding: 10px;
}
.realname-index .title {
  color: #4a4a4a;
  font-size: 12px;
  line-height: 1.35;
}
.realname-index .info {
  font-size: 12px;
  line-height: 1.35;
  margin-top: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.realname-index .info .text {
  color: #a6a6a6;
}
.realname-index .info .more {
  color: #ff5c5c;
}
.realname-index .input {
  margin-top: 10px;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.realname-index .input input {
  flex: 1 1;
  width: 0;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
  background-color: #efefef;
}
.realname-index .input .codebtn {
  margin-left: 10px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  padding: 7.5px 10px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.realname-index .input .codebtn.disable {
  background-image: none;
  background-color: #ccc;
}
.realname-index .operate {
  margin-top: 15px;
  background-image: linear-gradient(to right, #fe7072, #fc3d3e);
  color: #fff;
  text-align: center;
  padding: 7.5px;
  font-size: 15px;
  border-radius: 4px;
}
.realname-explain {
  padding: 10px 15px;
}
.realname-explain h1 {
  color: #4a4a4a;
  font-weight: 600;
}
.realname-explain h2 {
  margin-top: 5px;
  color: #4a4a4a;
  font-weight: 600;
}
.realname-explain p {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #a6a6a6;
  margin-top: 2.5px;
}
.realname-explain p:before {
  content: "*";
  color: red;
  margin-right: 5px;
}
.offline-offline {
  position: fixed;
  z-index: 9001;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(204, 204, 204, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}
.offline-offline > .container {
  height: 100%;
  position: relative;
  z-index: 9002;
  background-color: #fff;
  padding: 7.5px 5px;
  display: flex;
  flex-direction: column;
}
.offline-offline > .container > .title {
  font-weight: bold;
  color: #3d3d3d;
  position: relative;
  padding-left: 7.5px;
}
.offline-offline > .container > .title .close {
  position: absolute;
  top: 50%;
  right: 2.5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: red;
  display: flex;
  align-items: center;
  padding: 5px;
}
.offline-offline > .container > .title .close .icon {
  white-space: nowrap;
}
.offline-offline > .container > .title .close .text {
  white-space: nowrap;
  margin-right: 2.5px;
  line-height: 1;
}
.offline-offline > .container > .tips {
  background: #fff7f6;
  color: #ff3c3c;
  padding: 10px 5px;
  line-height: 1.25;
  margin-top: 7.5px;
  font-size: 13px;
}
.offline-offline .flex {
  flex: 1 1;
  height: 0;
  overflow-y: auto;
}
.offline-offline .voucher {
  border-radius: 2px;
  padding-left: 5px;
  padding-right: 10px;
  background: center center no-repeat;
  background-size: 100% 100%;
  margin: 10px 5px 0 5px;
  display: flex;
  align-items: center;
}
.offline-offline .voucher .amount {
  width: 24%;
  color: #c08f3d;
  font-weight: bold;
  text-align: center;
}
.offline-offline .voucher .amount span {
  font-size: 20px;
}
.offline-offline .voucher .data {
  flex: 1 1;
  width: 0;
  padding-left: 7.5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.offline-offline .voucher .data .name {
  color: #3d3d3d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.offline-offline .voucher .data .text {
  margin-top: 2.5px;
  color: rgba(61, 61, 61, 0.6);
  font-size: 13px;
}
.offline-offline .voucher .opt {
  background: #fcf15d;
  padding: 5px 10px;
  color: #3d3d3d;
  line-height: 1;
  border-radius: 20px;
  font-size: 13px;
  transition: all 0.25s;
}
.offline-offline .voucher .opt:before {
  content: "领取";
}
.offline-offline .voucher .opt.ing {
  background-color: #ccc;
}
.offline-offline .voucher .opt.ing:before {
  content: "领取中...";
}
.offline-offline .voucher .opt.disable {
  background-color: #ccc;
}
.offline-offline .voucher .opt.disable:before {
  content: "已领";
}
.offline-offline .game {
  margin-top: 10px;
}
.offline-offline .game .title {
  font-size: 16px;
  font-weight: bold;
  color: #3d3d3d;
}
.offline-offline .game .swiper-container {
  padding-bottom: 15px;
}
.offline-offline .game .swiper-pagination {
  bottom: 0;
}
.offline-offline .game .items .item {
  float: left;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offline-offline .game .items .item .icon {
  width: 100%;
  padding: 0 5px;
}
.offline-offline .game .items .item .icon .img {
  padding-top: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ede2e2;
  position: relative;
}
.offline-offline .game .items .item .icon img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.offline-offline .game .items .item .name {
  width: 100%;
  margin-top: 2.5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.offline-offline .game .items .item2 {
  float: left;
  width: 50%;
  margin-top: 10px;
}
.offline-offline .game .items .item2:nth-child(1),
.offline-offline .game .items .item2:nth-child(2) {
  margin-top: 0;
}
.offline-offline .game .items .item2 .main {
  display: flex;
  align-items: center;
}
.offline-offline .game .items .item2 .main .icon {
  width: 40%;
}
.offline-offline .game .items .item2 .main .icon .img {
  padding-top: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ede2e2;
  position: relative;
}
.offline-offline .game .items .item2 .main .icon img {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.offline-offline .game .items .item2 .main .data {
  width: 60%;
  line-height: 1;
  padding-left: 5px;
}
.offline-offline .game .items .item2 .main .data .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  color: #3d3d3d;
}
.offline-offline .game .items .item2 .main .data .desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 7.5px;
  font-size: 12px;
  color: red;
}
.offline-offline .game .items .item2 .main .data .tags {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 7.5px;
  font-size: 12px;
  color: #666;
}
.offline-offline .game .items .item2 .opt {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.offline-offline .game .items .item2 .opt .btn {
  min-width: 45%;
  border-radius: 15px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
  line-height: 1;
  padding: 5px 7.5px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.offline-offline .game .items .item2 .opt .btn:first-child {
  box-sizing: border-box;
  border: 1px solid #ff3c3c;
  color: #ff3c3c;
}
.offline-offline .game .items .item2 .opt .btn:last-child {
  background: #fcf15d;
  color: #3d3d3d;
}
.offline-offline .game .items .item2 .opt .btn:last-child:before {
  content: "领取礼包";
}
.offline-offline .game .items .item2 .opt .btn:last-child.no {
  opacity: 0.5;
}
.offline-offline .game .items .item2 .opt .btn:last-child.no:before {
  content: "暂无礼包";
}
.offline-offline .game .items .item2 .opt .btn:last-child.ing {
  opacity: 0.5;
}
.offline-offline .game .items .item2 .opt .btn:last-child.ing:before {
  content: "领取中";
}
.offline-offline .game .items .item2 .opt .btn:last-child.yes {
  opacity: 0.5;
}
.offline-offline .game .items .item2 .opt .btn:last-child.yes:before {
  content: "已领取";
}
.offline-offline .option .title {
  font-size: 16px;
  font-weight: bold;
  color: #3d3d3d;
}
.offline-offline .option .btns {
  margin-top: 5px;
  display: flex;
}
.offline-offline .option .btns .item {
  width: 50%;
}
.offline-offline .option .btns .item .container {
  display: flex;
  line-height: 1.25;
}
.offline-offline .option .btns .item .icon img {
  display: block;
  max-height: 35px;
}
.offline-offline .option .btns .item .info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offline-offline .option .btns .item .text {
  color: #3d3d3d;
  font-weight: bold;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.offline-offline .option .btns .item .desc {
  margin-top: 2.5px;
  color: rgba(61, 61, 61, 0.5);
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.offline-offline .app {
  display: flex;
  align-items: center;
  padding-top: 5px;
}
.offline-offline .app .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.offline-offline .app .icon img {
  display: block;
  width: 100%;
}
.offline-offline .app .data {
  flex: 1 1;
  width: 0;
  padding-left: 5px;
  padding-right: 5px;
  line-height: 1;
}
.offline-offline .app .data .name {
  font-size: 12px;
  color: #333333;
  font-weight: bold;
}
.offline-offline .app .data .desc {
  color: rgba(61, 61, 61, 0.6);
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 5px;
}
.offline-offline .app .data .tags {
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.offline-offline .app .data .tags .tag {
  background-color: red;
  margin-right: 5px;
  color: #fff;
  font-size: 10px;
  border-radius: 2px;
  padding: 2.5px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.offline-offline .app .opt .btn {
  background: linear-gradient(0deg, #ffd337 4%, #fdea90 100%);
  line-height: 1;
  padding: 7.5px 5px;
  font-size: 14px;
  color: #3d3d3d;
  border-radius: 15px;
}
@media screen and (max-width: 300px) {
  .offline-offline > .container > .title {
    font-size: 13px;
  }
  .offline-offline > .container > .title .close .text {
    font-size: 13px;
  }
  .offline-offline > .container > .tips {
    padding: 5px;
    font-size: 12px;
  }
  .offline-offline .voucher {
    margin: 5px 2.5px 0 2.5px;
  }
  .offline-offline .voucher .amount {
    font-size: 15px;
  }
  .offline-offline .voucher .data {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .offline-offline .voucher .data .name {
    font-size: 12px;
  }
  .offline-offline .voucher .data .text {
    font-size: 12px;
  }
  .offline-offline .game {
    margin-top: 5px;
    display: flex;
    align-items: flex-start;
  }
  .offline-offline .game .title {
    font-size: 12px;
    width: 20px;
    text-align: center;
    line-height: 1.35;
    padding-right: 5px;
    margin-right: 5px;
    border-right: 1px solid #ccc;
    display: flex;
    align-items: center;
  }
  .offline-offline .game .items {
    flex: 1 1;
    width: 0;
    overflow: hidden;
  }
  .offline-offline .game .items .swiper-pagination-bullet {
    width: 5px;
    height: 5px;
  }
  .offline-offline .game .items .item .name {
    font-size: 12px;
  }
  .offline-offline .game .items .item2 .opt .btn {
    font-size: 12px;
  }
  .offline-offline .option {
    display: flex;
    align-items: center;
  }
  .offline-offline .option .title {
    font-size: 12px;
    width: 35px;
    line-height: 1.25;
    text-align: center;
    margin-right: 7.5px;
    border-right: 1px solid #ccc;
  }
  .offline-offline .option .btns {
    flex: 1 1;
    width: 0;
    display: flex;
    justify-content: center;
  }
  .offline-offline .option .btns .item {
    display: flex;
  }
  .offline-offline .option .btns .item .container {
    align-items: center;
    line-height: 1.25;
  }
  .offline-offline .option .btns .item .icon {
    margin-right: 5px;
  }
  .offline-offline .option .btns .item .icon img {
    width: 30px;
    height: 30px;
    max-width: unset;
    max-height: unset;
  }
  .offline-offline .option .btns .item .info {
    align-items: flex-start;
  }
  .offline-offline .option .btns .item .text {
    margin-top: auto;
    font-size: 13px;
  }
  .offline-offline .option .btns .item .desc {
    font-size: 10px;
  }
  .offline-offline .app {
    padding-top: 2.5px;
  }
}
@media screen and (min-width: 333px) {
  .offline-offline > .container {
    width: 85%;
    height: auto;
    border-radius: 8px;
  }
  .offline-offline > .container > .title {
    font-size: 15px;
  }
  .offline-offline > .container > .title .close .icon {
    font-size: 15px;
  }
  .offline-offline > .container > .tips {
    font-size: 14px;
  }
  .offline-offline .voucher .amount {
    font-size: 16px;
  }
  .offline-offline .voucher .data {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .offline-offline .voucher .data .name {
    font-size: 14px;
  }
  .offline-offline .voucher .data .text {
    margin-top: 2.5px;
    font-size: 13px;
  }
  .offline-offline .voucher .opt {
    background: #fcf15d;
    padding: 5px 10px;
    color: #3d3d3d;
    line-height: 1.1;
    border-radius: 20px;
    font-size: 13px;
    transition: all 0.25s;
  }
  .offline-offline .voucher .opt:before {
    content: "领取";
  }
  .offline-offline .voucher .opt.ing {
    background-color: #ccc;
  }
  .offline-offline .voucher .opt.ing:before {
    content: "领取中...";
  }
  .offline-offline .voucher .opt.disable {
    background-color: #ccc;
  }
  .offline-offline .voucher .opt.disable:before {
    content: "已领";
  }
  .offline-offline .game .title {
    font-size: 15px;
  }
  .offline-offline .game .items {
    margin-top: 5px;
  }
  .offline-offline .game .items .swiper-container {
    padding-bottom: 20px;
  }
  .offline-offline .game .items .item .text {
    font-size: 12px;
  }
  .offline-offline .option {
    padding: 5px;
  }
  .offline-offline .option .title {
    font-size: 16px;
  }
  .offline-offline .option .btns {
    display: flex;
  }
  .offline-offline .option .btns .item {
    width: 50%;
  }
  .offline-offline .option .btns .item .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.25;
  }
  .offline-offline .option .btns .item .icon img {
    max-height: 35px;
  }
  .offline-offline .option .btns .item .text {
    font-size: 15px;
  }
  .offline-offline .option .btns .item .desc {
    font-size: 14px;
  }
}
.newuser-newuser {
  position: fixed;
  z-index: 10002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #3d3d3d;
}
.newuser-newuser > .container {
  position: fixed;
  z-index: 10003;
  top: 52%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 7.5px 5px;
  display: flex;
  flex-direction: column;
}
.newuser-newuser > .container > .title {
  font-weight: bold;
  color: #3d3d3d;
  position: relative;
  padding-left: 7.5px;
  text-align: center;
}
.newuser-newuser > .container > .title .close {
  position: absolute;
  top: 50%;
  right: 2.5px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: red;
  display: flex;
  align-items: center;
  padding: 5px;
}
.newuser-newuser > .container > .title .close .icon {
  white-space: nowrap;
}
.newuser-newuser > .container > .title .close .text {
  white-space: nowrap;
  margin-right: 2.5px;
  line-height: 1;
}
.newuser-newuser > .container > .tips {
  background: #fff7f6;
  color: #ff3c3c;
  text-align: center;
  padding: 10px 5px;
  line-height: 1.1;
  margin-left: -5px;
  margin-right: -5px;
  margin-top: 7.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.newuser-newuser .flex {
  flex: 1 1;
  overflow-y: auto;
  padding: 8px;
}
.newuser-newuser .part-one .voucher-title {
  margin-top: 15px;
}
.newuser-newuser .part-one .voucher-title img {
  height: 13px;
}
.newuser-newuser .part-one .voucher {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.newuser-newuser .part-one .voucher .item {
  background: center center no-repeat;
  background-size: 100% 100%;
  width: 89px;
  height: 95px;
  margin-right: 7px;
}
.newuser-newuser .part-one .voucher .item .amount {
  font-size: 12px;
  color: #63422d;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 17px;
}
.newuser-newuser .part-one .voucher .item .amount .num {
  font-weight: bold;
  font-size: 26px;
}
.newuser-newuser .part-one .voucher .item .meet {
  color: #6b5521;
  font-size: 12px;
  text-align: center;
}
.newuser-newuser .part-one .opt {
  color: #fff;
  height: 44px;
  line-height: 44px;
  background-image: linear-gradient(#fe5c42, #fa312b);
  text-align: center;
  border-radius: 20px;
  font-size: 17.2px;
  font-weight: bold;
  transition: all 0.25s;
  width: 85%;
  margin-left: 15px;
}
.newuser-newuser .part-one .opt:before {
  content: "一键领取";
}
.newuser-newuser .part-one .opt.ing {
  background-color: #ccc;
}
.newuser-newuser .part-one .opt.ing:before {
  content: "领取中...";
}
.newuser-newuser .part-one .opt.disable {
  background-color: #ccc;
}
.newuser-newuser .part-one .opt.disable:before {
  content: "已领取";
}
.newuser-newuser .part-one .tip {
  color: rgba(61, 61, 61, 0.6);
  font-size: 10px;
  text-align: center;
  margin-top: 5px;
}
.newuser-newuser .part-two .pack-title {
  margin-top: 15px;
}
.newuser-newuser .part-two .pack-title img {
  height: 13px;
}
.newuser-newuser .part-two .pack-info {
  margin-left: 8px;
}
.newuser-newuser .part-two .pack-info .pack {
  display: flex;
  flex-direction: row;
  margin-top: 9px;
}
.newuser-newuser .part-two .pack-info .pack .icon img {
  width: 65px;
}
.newuser-newuser .part-two .pack-info .pack .title {
  width: 50%;
  margin-top: 5px;
  margin-left: 8px;
}
.newuser-newuser .part-two .pack-info .pack .title .main-title {
  font-size: 14px;
  font-weight: bold;
}
.newuser-newuser .part-two .pack-info .pack .title .sub-title {
  color: #ff3c3c;
  font-size: 10px;
  font-weight: bold;
}
.newuser-newuser .part-two .pack-info .pack .title .type {
  color: #767676;
  font-size: 8px;
}
.newuser-newuser .part-two .pack-info .pack .opt {
  background: #fcf15d;
  padding: 5px 7px;
  color: #3d3d3d;
  line-height: 1.1;
  border-radius: 20px;
  font-size: 10px;
  transition: all 0.25s;
  font-weight: bold;
  margin-top: 40px;
  height: 24px;
}
.newuser-newuser .part-two .pack-info .pack .opt:before {
  content: "领取礼包";
}
.newuser-newuser .part-two .pack-info .pack .opt.ing {
  background-color: #ccc;
}
.newuser-newuser .part-two .pack-info .pack .opt.ing:before {
  content: "领取中...";
}
.newuser-newuser .part-two .pack-info .pack .opt.disable {
  background-color: #ccc;
}
.newuser-newuser .part-two .pack-info .pack .opt.disable:before {
  content: "已领取";
}
.newuser-newuser .part-two .pack-info .recharge-title {
  display: flex;
  flex-direction: column;
  position: relative;
}
.newuser-newuser .part-two .pack-info .recharge-title span {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
  margin-top: 12px;
  color: #ff3c3c;
  font-size: 13px;
  font-weight: bold;
  margin-left: 12px;
}
.newuser-newuser .part-two .pack-info .recharge-title img {
  width: 109px;
  height: 25px;
  margin-top: 9px;
}
.newuser-newuser .part-two .pack-info .recharge-content {
  background: #f5f6f8;
  height: 25px;
  border-radius: 0 5px 5px 5px;
  opacity: 1;
  width: 95%;
}
.newuser-newuser .part-two .pack-info .recharge-content span {
  font-size: 10px;
  margin-left: 12px;
}
.newuser-newuser .download-box {
  position: fixed;
  z-index: 10003;
  top: 90%;
  left: 50%;
  width: 92%;
  height: 20%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 9px 9px;
  border-radius: 9px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.newuser-newuser .download-box .download-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.newuser-newuser .download-box .download-info .logo {
  width: 16.5%;
  margin-right: 6px;
}
.newuser-newuser .download-box .download-info .info {
  flex: 1 1;
  white-space: nowrap;
  line-height: 1.1;
  margin-top: -5px;
}
.newuser-newuser .download-box .download-info .info .title {
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}
.newuser-newuser .download-box .download-info .info .tip {
  color: rgba(61, 61, 61, 0.6);
  font-size: 9px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.newuser-newuser .download-box .download-info .info .tag .icon {
  color: #ffffff;
  background: #ff3c3c;
  width: 25px;
  height: 10px;
  line-height: 10px;
  border-radius: 2px;
  opacity: 1;
  font-size: 7px;
  padding: 2px 2px;
  margin-right: 5px;
  font-weight: bold;
}
.newuser-newuser .download-box .download-info .download-btn {
  background-image: linear-gradient(#fdea90, #ffd337);
  height: 23px;
  line-height: 23px;
  text-align: center;
  border-radius: 18px;
  font-size: 9px;
  font-weight: bold;
  width: 17%;
}
.newuser-newuser .pack-receive {
  position: fixed;
  z-index: 10004;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.newuser-newuser .pack-receive .body {
  width: 75%;
  height: 66%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 12px;
}
.newuser-newuser .pack-receive .body .title {
  font-size: 16px;
  font-weight: bold;
}
.newuser-newuser .pack-receive .body .content {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
.newuser-newuser .pack-receive .body .content .btn {
  background: #fcf15d;
  padding: 5px 10px;
  color: #3d3d3d;
  line-height: 1.1;
  border-radius: 20px;
  font-size: 10px;
  transition: all 0.25s;
  font-weight: bold;
  height: 24px;
}
.newuser-newuser .pack-receive .body .use {
  color: rgba(61, 61, 61, 0.6);
  font-size: 9px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 15px;
  text-align: left;
}
.newuser-newuser .pack-receive .body .confirm {
  color: #fb6c31;
  margin-top: 13px;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  opacity: 0.7;
  padding: 5px;
}
.newuser-newuser .remind {
  position: fixed;
  z-index: 10004;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.newuser-newuser .remind .body {
  width: 75%;
  height: 52%;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.newuser-newuser .remind .body .title {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 15px 0 15px;
}
.newuser-newuser .remind .body .content {
  padding-top: 6px;
}
.newuser-newuser .remind .body .content .message {
  font-size: 14px;
  font-weight: bold;
}
.newuser-newuser .remind .body .content .option {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 13px;
}
.newuser-newuser .remind .body .content .option .input {
  border: 1px solid #d6d6d6;
  width: 17.5px;
  height: 17.5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.newuser-newuser .remind .body .content .option .input .icon {
  font-size: 10px;
  transition: all 0.25s;
  opacity: 0;
}
.newuser-newuser .remind .body .content .option .input.active {
  background-color: #fe7072;
  border: none;
}
.newuser-newuser .remind .body .content .option .input.active .icon {
  opacity: 1;
  color: #fff;
}
.newuser-newuser .remind .body .content .not-remind {
  font-size: 12px;
}
.newuser-newuser .remind .body .btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 14px;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  height: 43px;
}
.newuser-newuser .remind .body .btn .again {
  color: #fb6c31;
  font-size: 14px;
  font-weight: bold;
}
.newuser-newuser .remind .body .btn .line {
  height: 100%;
  border-right: 1px solid rgba(216, 216, 216, 0.5);
}
.newuser-newuser .remind .body .btn .sure {
  color: #aaaaaa;
  font-size: 14px;
}
@media screen and (max-width: 300px) {
  .newuser-newuser > .container > .title {
    font-size: 13px;
  }
  .newuser-newuser > .container > .title .close .text {
    font-size: 13px;
  }
  .newuser-newuser > .container > .tips {
    font-size: 13px;
  }
}
@media screen and (min-width: 333px) {
  .newuser-newuser > .container {
    width: 85%;
    height: 67%;
    border-radius: 9px;
  }
  .newuser-newuser > .container > .title {
    font-size: 16px;
  }
  .newuser-newuser > .container > .title .close .icon {
    font-size: 15px;
  }
  .newuser-newuser > .container > .tips {
    font-size: 15px;
  }
  .newuser-newuser > .download-box {
    width: 85%;
    height: 9%;
    border-radius: 9px;
  }
}
/*# sourceMappingURL=css.css.map */

.root-old {
  padding-bottom: 55px;
}
.root-old .side-container .side-menu {
  bottom: 0;
  left: 0;
  right: 0;
  height: 55px;
  width: auto;
  flex-direction: unset;
}
.root-old .side-container .side-main {
  padding-left: 0;
}
.root-old .side-container .side-title {
  left: 0;
}
.root-old .side-container .nav-title {
  left: 0;
}
.root-old .side-container .user-voucher .head {
  left: 0;
}
.root-old .side-container .welfare-head {
  left: 0;
}
.root-old .side-container .trade-trades .trade-head {
  left: 0;
}
/*# sourceMappingURL=old.css.map */
